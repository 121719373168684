<template>
  <div class="right">
    <span class="spen">我的订单</span>
    <div class="menu">
      <div class="daohang">
        <ul class="ul">
          <router-link :to="{ name: 'Dingdan' }" tag="li">全部订单</router-link>
          <router-link :to="{ name: 'Fukuan' }" tag="li">待付款</router-link>
          <router-link :to="{ name: 'Fahuo' }" tag="li">待发货</router-link>
          <router-link :to="{ name: 'Shouhuo' }" tag="li">待收货</router-link>
          <router-link :to="{ name: 'Ping' }" tag="li">待评价</router-link>
          <router-link :to="{ name: 'Huishou' }" tag="li"
            >订单回收站</router-link
          >
        </ul>
      </div>
      <Loading v-if="isLoading1" />
      <div class="search">
        <input
          type="text"
          v-model="mes"
          @keyup.enter.prevent="search"
          placeholder="输入商品订单号或商品名称"
        />
        <div class="fangdajing" @click="search">
          <Uimage src="../../../assets/order/fangdajing.png" alt="" />
        </div>
      </div>
    </div>
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="tishi" v-if="order_list == ''">{{ tishi }}</div>
      <div v-for="(item, index) in order_list" :key="index">
        <div class="top">
          <div class="biaoti">{{ item.createTime }}</div>
          <div class="biaoti mar">
            订单号：<span class="danhao">{{ item.orderNo }}</span>
          </div>
          <div class="biaoti mar dianpu" @click="dianpu(item)">
            店铺：{{ item.storeName }}
          </div>
          <!-- <div class="biaoti mar kefu">
            <span>客服</span><Uimage src="../../../assets/order/kefu.png" />
          </div> -->
        </div>
        <div class="bottom" v-for="(res, k) in item.orderSkuList" :key="k">
          <div class="pic">
            <Uimage :src="res.url" alt="" />
          </div>
          <div class="title1">
            <div class="title">
              <div class="miaoshu" @click="xiangqing(item.storeId, res.spuId)">
                {{ res.spuName }}
              </div>
              <div class="shuliang">X{{ res.skuCount }}</div>
            </div>
            <!-- <div class="xiangsi">找相似</div> -->
            <!-- 商品属性 -->
            <div class="shuxing" v-for="(q, c) in res.saleAttrCode" :key="c">
              <div v-if="q.attrName">{{ q.attrName }}：</div>
              <div v-if="q.attrValue" v-html="q.attrValue"></div>
            </div>
          </div>
          <div class="tousu">
            <!--            <router-link :to="{ name: 'Tuihuosq' }" tag="div"-->
            <!--              >申请售后</router-link-->
            <!--            >-->

            <span v-if="res.isTrue == 'true'">投诉卖家</span>
          </div>
          <div class="price">
            <div class="jiage">
              ￥{{ Number(res.skuRealPrice * res.skuCount).toFixed(2) }}
            </div>
            <!-- <div class="yunfei">
              (含运费:￥{{ Number(res.freight).toFixed(2) }})
            </div> -->
            <div class="fangshi" v-if="item.orderSource == 4">积分兑换</div>
            <div class="fangshi" v-else>在线支付</div>
          </div>
          <div class="zhuangtai">
            <!-- <div>等待买家付款</div> -->
            <div
              class="xiangqing"
              @click="ding_detail(res)"
              v-if="res.isTrue == 'true'"
            >
              订单详情
            </div>
          </div>
          <div class="pay">
            <div
              class="pingzheng"
              v-if="item.payBasic != null && res.isTrue == 'true'"
              @click="lookVoucher(item)"
            >
              修改上传凭证
            </div>
          </div>
        </div>
      </div>
      <div class="fenye" v-if="order_list != ''">
        <el-pagination
          @current-change="pagechange"
          :current-page="page.current"
          style="text-align: right;margin-top: 20px"
          background
          :total="page.total"
          :page-size="page.size"
          layout="prev, pager, next, jumper, ->, total"
        >
        </el-pagination>
      </div>

      <el-dialog
        title="对公打款"
        :visible.sync="dialogVisible1"
        width="40%"
        :before-close="handleClose"
      >
        <el-form
          ref="formdata"
          :model="formdata"
          label-width="140px"
          :rules="rules"
        >
          <el-form-item label="交易编号" prop="payRemittanceId">
            <el-input v-model="formdata.payRemittanceId"></el-input>
          </el-form-item>
          <el-form-item label="付款人姓名" prop="payerName">
            <el-input v-model="formdata.payerName"></el-input>
          </el-form-item>
          <el-form-item label="付款账号" prop="payerCardNumber">
            <el-input v-model="formdata.payerCardNumber"></el-input>
          </el-form-item>
          <el-form-item label="付款摘要" prop="paymentSummary">
            <el-input v-model="formdata.paymentSummary"></el-input>
          </el-form-item>
          <el-form-item label="请上传付款凭证：" prop="imageUrl">
            <el-upload
              class="avatar-uploader"
              :action="picurl"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              list-type="picture-card"
            >
              <Uimage
                v-if="formdata.imageUrl"
                :src="formdata.imageUrl"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible1 = false">取 消</el-button>
          <el-button type="primary" @click="confirm('formdata')"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { setContextData, getContextData } from '../../../utils/session.js';
import { post, get } from '@/utils/request';
import { baseURL } from '@/utils/request';
export default {
  name: 'Foot',
  props: {
    msg: String,
  },
  data() {
    return {
      order_list: [],
      isLoading: true,
      isLoading1: false,
      currentPage: 1,
      totalNum: 1,
      mes: '',
      tishi: '空空如也~',
      wuliu_list: [],
      dialogFormVisible: false,
      dialogVisible: false,
      page: {
        total: 1,
        size: 10,
        current: 1,
      },
      form: {
        orderId: '',
        invType: '',
        region: '',
        ivcTitleType: '',
        invTitle: '',
        registCode: '',
        registBank: '',
        bankAccount: '',
        registAddress: '',
        registPhone: '',
      },
      dialogVisible1: false,
      formdata: {
        payRemittanceId: '',
        payerName: '',
        payerCardNumber: '',
        paymentSummary: '',
        imageUrl: '',
      },
      orderId: '',
      picurl: baseURL + 'f/api/frontCommon/payVoucher',
      rules: {
        invTitle: [
          { required: true, message: '请填写发票抬头', trigger: 'blur' },
        ],
        registCode: [
          { required: true, message: '请填写税号', trigger: 'blur' },
        ],
        payRemittanceId: [
          { required: true, message: '请输入交易编号', trigger: 'blur' },
          {
            min: 1,
            max: 100,
            message: '长度在 1 到 100 个字符',
            trigger: 'blur',
          },
        ],
        payerName: [
          { required: true, message: '请输入付款人姓名', trigger: 'blur' },
          {
            min: 1,
            max: 100,
            message: '长度在 1 到 100 个字符',
            trigger: 'blur',
          },
        ],
        payerCardNumber: [
          { required: true, message: '请输入付款账号', trigger: 'blur' },
          {
            min: 1,
            max: 50,
            message: '长度在 1 到 50 个字符',
            trigger: 'blur',
          },
        ],
        paymentSummary: [
          { required: true, message: '请输入付款摘要', trigger: 'blur' },
          {
            min: 1,
            max: 100,
            message: '长度在 1 到 100 个字符',
            trigger: 'blur',
          },
        ],
        imageUrl: [
          { required: true, message: '请上传付款凭证', trigger: 'blur' },
        ],
      },
    };
  },
  created() {
    this.currentPage = getContextData('currentPage2') || 1;
    this.getorder(this.currentPage);
  },
  methods: {
    xiangqing(storeId, spuId) {
      const { href } = this.$router.resolve({
        name: 'detail',
        params: {
          //店铺id
          storeId: storeId,
          //商品id
          spu: spuId,
        },
      });
      window.open(href, '_blank');
    },
    //店铺
    dianpu(e) {
      // this.$router.push({
      //   name: 'store_index',
      //   params: {
      //     storeId: e.storeId,
      //   },
      // });
      const { href } = this.$router.resolve({
        name: 'store_index',
        params: {
          storeId: e.storeId,
        },
      });
      window.open(href, '_blank');
    },
    lookVoucher(e) {
      this.formdata.payRemittanceId = e.payBasic.payRemittanceId;
      this.formdata.payerName = e.payBasic.payerName;
      this.formdata.payerCardNumber = e.payBasic.payerCardNumber;
      this.formdata.paymentSummary = e.payBasic.paymentSummary;
      this.formdata.imageUrl = e.payBasic.paymentVoucher;
      this.orderId = e.payBasic.orderId;
      this.dialogVisible1 = true;
    },
    confirm(formdata) {
      this.$refs[formdata].validate((valid) => {
        if (valid) {
          post('api/orderbasic/updatePayBasic', {
            paymentVoucher: this.coverImg,
            orderId: this.orderId,
            paymentAmount: this.sumPrice,
            payRemittanceId: this.formdata.payRemittanceId,
            payerName: this.formdata.payerName,
            payerCardNumber: this.formdata.payerCardNumber,
            paymentSummary: this.formdata.paymentSummary,
          }).then((res) => {
            this.dialogVisible1 = false;
            this.getorder(this.currentPage);
          });
        } else {
          return false;
        }
      });
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    handleAvatarSuccess(res, file) {
      this.coverImg = res.fileName;
      this.formdata.imageUrl = res.url;
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === 'image/jpeg' ||
        'image/jpg' ||
        'image/webp' ||
        'image/png';
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error({
          message: '图片格式不正确!(只能包含jpg，png，webp，JPEG)',
        });
      }
      if (!isLt2M) {
        this.$message.error({
          message: '上传头像图片大小不能超过 10MB!',
        });
      }
      return isJPG && isLt2M;
    },
    tuikuan(e) {
      this.$router.push({
        name: 'Tuihuosq',
        params: {
          skuId: e.skuId,
          orderId: e.orderId,
        },
      });
    },

    pagechange(p) {
      this.isLoading = true;
      this.page.current = p;
      setContextData('currentPage2', this.currentPage);
      get(
        'api/orderbasic/getOrderByMemberId?pageNum=' +
          p +
          '&pageSize=10&orderStatus=1'
      ).then((res) => {
        this.isLoading = false;
        res.data.data.list.forEach((req) => {
          if (req.orderSource == 4) {
            req.orderSkuList[0].skuRealPrice = 0;
          }
          req.orderSkuList.forEach((q) => {
            q.saleAttrCode = eval('(' + q.saleAttrCode + ')');
            if (q.value != '0mol/mol' && q.value != '0ppm' && q.value != null) {
              if (q.value.indexOf('mol/mol') != -1) {
                q.saleAttrCode.push({
                  attrName: '确认含量',
                  attrValue: q.value.replace(
                    'mol/mol',
                    'X10<sup>-6</sup>mol/mol'
                  ),
                });
              } else {
                q.saleAttrCode.push({
                  attrName: '确认含量',
                  attrValue: q.value,
                });
              }
            }
            q.saleAttrCode.forEach((k, index) => {
              if (k.attrName == '浓度范围') {
                q.saleAttrCode.splice(index, 1);
              }
            });
            Object.assign(q, { isTrue: 'false' });
          });
          for (let q = 0; q < req.orderSkuList.length; q++) {
            for (let j = q + 1; j < req.orderSkuList.length; j++) {
              if (
                req.orderSkuList[q].remark == '1' &&
                req.orderSkuList[j].remark == '1'
              ) {
                if (
                  req.orderSkuList[q].skuName == req.orderSkuList[j].skuName &&
                  req.orderSkuList[q].storeId == req.orderSkuList[j].storeId &&
                  req.orderSkuList[q].prodCatId ==
                    req.orderSkuList[j].prodCatId &&
                  req.orderSkuList[q].batchNumber ==
                    req.orderSkuList[j].batchNumber
                ) {
                  for (
                    let p = 0;
                    p < req.orderSkuList[j].saleAttrCode.length;
                    p++
                  ) {
                    if (
                      req.orderSkuList[j].saleAttrCode[p].attrName ==
                        '多组分' ||
                      req.orderSkuList[j].saleAttrCode[p].attrName ==
                        '气体组分' ||
                      req.orderSkuList[j].saleAttrCode[p].attrName == '确认含量'
                    ) {
                      req.orderSkuList[q].saleAttrCode.push(
                        req.orderSkuList[j].saleAttrCode[p]
                      );
                    }
                  }
                }
              }
            }
          }
          req.orderSkuList[0].isTrue = 'true';
        });
        res.data.data.list.forEach((i) => {
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q + 1; j < i.orderSkuList.length; j++) {
              if (
                i.orderSkuList[q].remark == '1' &&
                i.orderSkuList[j].remark == '1'
              ) {
                if (
                  i.orderSkuList[q].skuName == i.orderSkuList[j].skuName &&
                  i.orderSkuList[q].storeId == i.orderSkuList[j].storeId &&
                  i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId &&
                  i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber
                ) {
                  i.orderSkuList.splice(j, 1);
                }
              }
            }
          }
        });
        res.data.data.list.forEach((i) => {
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q + 1; j < i.orderSkuList.length; j++) {
              if (
                i.orderSkuList[q].remark == '1' &&
                i.orderSkuList[j].remark == '1'
              ) {
                if (
                  i.orderSkuList[q].skuName == i.orderSkuList[j].skuName &&
                  i.orderSkuList[q].storeId == i.orderSkuList[j].storeId &&
                  i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId &&
                  i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber
                ) {
                  i.orderSkuList.splice(j, 1);
                }
              }
            }
          }
        });
        res.data.data.list.forEach((i) => {
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q + 1; j < i.orderSkuList.length; j++) {
              if (
                i.orderSkuList[q].remark == '1' &&
                i.orderSkuList[j].remark == '1'
              ) {
                if (
                  i.orderSkuList[q].skuName == i.orderSkuList[j].skuName &&
                  i.orderSkuList[q].storeId == i.orderSkuList[j].storeId &&
                  i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId &&
                  i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber
                ) {
                  i.orderSkuList.splice(j, 1);
                }
              }
            }
          }
        });
        this.order_list = res.data.data.list;
      });
    },
    getorder(e) {
      get(
        'api/orderbasic/getOrderByMemberId?pageNum=' +
          e +
          '&pageSize=10&orderStatus=1'
      ).then((res) => {
        this.isLoading = false;
        res.data.data.list.forEach((req) => {
          if (req.orderSource == 4) {
            req.orderSkuList[0].skuRealPrice = 0;
          }
          req.orderSkuList.forEach((q) => {
            q.saleAttrCode = eval('(' + q.saleAttrCode + ')');
            if (q.value != '0mol/mol' && q.value != '0ppm' && q.value != null) {
              if (q.value.indexOf('mol/mol') != -1) {
                q.saleAttrCode.push({
                  attrName: '确认含量',
                  attrValue: q.value.replace(
                    'mol/mol',
                    'X10<sup>-6</sup>mol/mol'
                  ),
                });
              } else {
                q.saleAttrCode.push({
                  attrName: '确认含量',
                  attrValue: q.value,
                });
              }
            }
            q.saleAttrCode.forEach((k, index) => {
              if (k.attrName == '浓度范围') {
                q.saleAttrCode.splice(index, 1);
              }
            });
            Object.assign(q, { isTrue: 'false' });
          });
          for (let q = 0; q < req.orderSkuList.length; q++) {
            for (let j = q + 1; j < req.orderSkuList.length; j++) {
              if (
                req.orderSkuList[q].remark == '1' &&
                req.orderSkuList[j].remark == '1'
              ) {
                if (
                  req.orderSkuList[q].skuName == req.orderSkuList[j].skuName &&
                  req.orderSkuList[q].storeId == req.orderSkuList[j].storeId &&
                  req.orderSkuList[q].prodCatId ==
                    req.orderSkuList[j].prodCatId &&
                  req.orderSkuList[q].batchNumber ==
                    req.orderSkuList[j].batchNumber
                ) {
                  for (
                    let p = 0;
                    p < req.orderSkuList[j].saleAttrCode.length;
                    p++
                  ) {
                    if (
                      req.orderSkuList[j].saleAttrCode[p].attrName ==
                        '多组分' ||
                      req.orderSkuList[j].saleAttrCode[p].attrName ==
                        '气体组分' ||
                      req.orderSkuList[j].saleAttrCode[p].attrName == '确认含量'
                    ) {
                      req.orderSkuList[q].saleAttrCode.push(
                        req.orderSkuList[j].saleAttrCode[p]
                      );
                    }
                  }
                }
              }
            }
          }
          req.orderSkuList[0].isTrue = 'true';
        });
        res.data.data.list.forEach((i) => {
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q + 1; j < i.orderSkuList.length; j++) {
              if (
                i.orderSkuList[q].remark == '1' &&
                i.orderSkuList[j].remark == '1'
              ) {
                if (
                  i.orderSkuList[q].skuName == i.orderSkuList[j].skuName &&
                  i.orderSkuList[q].storeId == i.orderSkuList[j].storeId &&
                  i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId &&
                  i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber
                ) {
                  i.orderSkuList.splice(j, 1);
                }
              }
            }
          }
        });
        res.data.data.list.forEach((i) => {
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q + 1; j < i.orderSkuList.length; j++) {
              if (
                i.orderSkuList[q].remark == '1' &&
                i.orderSkuList[j].remark == '1'
              ) {
                if (
                  i.orderSkuList[q].skuName == i.orderSkuList[j].skuName &&
                  i.orderSkuList[q].storeId == i.orderSkuList[j].storeId &&
                  i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId &&
                  i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber
                ) {
                  i.orderSkuList.splice(j, 1);
                }
              }
            }
          }
        });
        res.data.data.list.forEach((i) => {
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q + 1; j < i.orderSkuList.length; j++) {
              if (
                i.orderSkuList[q].remark == '1' &&
                i.orderSkuList[j].remark == '1'
              ) {
                if (
                  i.orderSkuList[q].skuName == i.orderSkuList[j].skuName &&
                  i.orderSkuList[q].storeId == i.orderSkuList[j].storeId &&
                  i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId &&
                  i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber
                ) {
                  i.orderSkuList.splice(j, 1);
                }
              }
            }
          }
        });
        this.order_list = res.data.data.list;
        this.page.total = res.data.data.total;
      });
    },
    //订单详情
    ding_detail(e) {
      this.$router.push({
        name: 'Detail_dingdan',
        query: {
          //skuId: e.skuId,
          orderId: e.orderId,
        },
      });
    },
    //搜索
    search() {
      this.isLoading = true;
      get(
        'api/orderbasic/getOrderByMemberId?orderStatus=2&orderNo=' + this.mes
      ).then((res) => {
        this.isLoading = false;
        if (res.data.data.list == '') {
          this.tishi = '未查到相关订单~';
          res.data.data.list.forEach((req) => {
            if (req.orderSource == 4) {
              req.orderSkuList[0].skuRealPrice = 0;
            }
          });
          this.order_list = res.data.data.list;
        } else {
          res.data.data.list.forEach((req) => {
            if (req.orderSource == 4) {
              req.orderSkuList[0].skuRealPrice = 0;
            }
            req.orderSkuList.forEach((q) => {
              q.saleAttrCode = eval('(' + q.saleAttrCode + ')');
              if (
                q.value != '0mol/mol' &&
                q.value != '0ppm' &&
                q.value != null
              ) {
                if (q.value.indexOf('mol/mol') != -1) {
                  q.saleAttrCode.push({
                    attrName: '确认含量',
                    attrValue: q.value.replace(
                      'mol/mol',
                      'X10<sup>-6</sup>mol/mol'
                    ),
                  });
                } else {
                  q.saleAttrCode.push({
                    attrName: '确认含量',
                    attrValue: q.value,
                  });
                }
              }
              q.saleAttrCode.forEach((k, index) => {
                if (k.attrName == '浓度范围') {
                  q.saleAttrCode.splice(index, 1);
                }
              });
              Object.assign(q, { isTrue: 'false' });
            });
            //   req.orderSkuList[0].isTrue = 'true';
            // });
            for (let q = 0; q < req.orderSkuList.length; q++) {
              for (let j = q + 1; j < req.orderSkuList.length; j++) {
                if (
                  req.orderSkuList[q].remark == '1' &&
                  req.orderSkuList[j].remark == '1'
                ) {
                  if (
                    req.orderSkuList[q].skuName ==
                      req.orderSkuList[j].skuName &&
                    req.orderSkuList[q].storeId ==
                      req.orderSkuList[j].storeId &&
                    req.orderSkuList[q].prodCatId ==
                      req.orderSkuList[j].prodCatId &&
                    req.orderSkuList[q].batchNumber ==
                      req.orderSkuList[j].batchNumber
                  ) {
                    for (
                      let p = 0;
                      p < req.orderSkuList[j].saleAttrCode.length;
                      p++
                    ) {
                      if (
                        req.orderSkuList[j].saleAttrCode[p].attrName ==
                          '多组分' ||
                        req.orderSkuList[j].saleAttrCode[p].attrName ==
                          '气体组分' ||
                        req.orderSkuList[j].saleAttrCode[p].attrName ==
                          '确认含量'
                      ) {
                        req.orderSkuList[q].saleAttrCode.push(
                          req.orderSkuList[j].saleAttrCode[p]
                        );
                      }
                    }
                  }
                }
              }
            }
            req.orderSkuList[0].isTrue = 'true';
          });
          res.data.data.list.forEach((i) => {
            for (let q = 0; q < i.orderSkuList.length; q++) {
              for (let j = q + 1; j < i.orderSkuList.length; j++) {
                if (
                  i.orderSkuList[q].remark == '1' &&
                  i.orderSkuList[j].remark == '1'
                ) {
                  if (
                    i.orderSkuList[q].skuName == i.orderSkuList[j].skuName &&
                    i.orderSkuList[q].storeId == i.orderSkuList[j].storeId &&
                    i.orderSkuList[q].prodCatId ==
                      i.orderSkuList[j].prodCatId &&
                    i.orderSkuList[q].batchNumber ==
                      i.orderSkuList[j].batchNumber
                  ) {
                    i.orderSkuList.splice(j, 1);
                  }
                }
              }
            }
          });
          res.data.data.list.forEach((i) => {
            for (let q = 0; q < i.orderSkuList.length; q++) {
              for (let j = q + 1; j < i.orderSkuList.length; j++) {
                if (
                  i.orderSkuList[q].remark == '1' &&
                  i.orderSkuList[j].remark == '1'
                ) {
                  if (
                    i.orderSkuList[q].skuName == i.orderSkuList[j].skuName &&
                    i.orderSkuList[q].storeId == i.orderSkuList[j].storeId &&
                    i.orderSkuList[q].prodCatId ==
                      i.orderSkuList[j].prodCatId &&
                    i.orderSkuList[q].batchNumber ==
                      i.orderSkuList[j].batchNumber
                  ) {
                    i.orderSkuList.splice(j, 1);
                  }
                }
              }
            }
          });
          res.data.data.list.forEach((i) => {
            for (let q = 0; q < i.orderSkuList.length; q++) {
              for (let j = q + 1; j < i.orderSkuList.length; j++) {
                if (
                  i.orderSkuList[q].remark == '1' &&
                  i.orderSkuList[j].remark == '1'
                ) {
                  if (
                    i.orderSkuList[q].skuName == i.orderSkuList[j].skuName &&
                    i.orderSkuList[q].storeId == i.orderSkuList[j].storeId &&
                    i.orderSkuList[q].prodCatId ==
                      i.orderSkuList[j].prodCatId &&
                    i.orderSkuList[q].batchNumber ==
                      i.orderSkuList[j].batchNumber
                  ) {
                    i.orderSkuList.splice(j, 1);
                  }
                }
              }
            }
          });
          this.order_list = res.data.data.list;
          this.page.total = res.data.data.total;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar {
  width: 400px;
  height: 200px;
}
.tishi {
  width: 90%;
  text-align: center;
  margin-top: 150px;
  font-size: 24px;
  color: rgb(139, 135, 135);
}
.fenye {
  margin-top: 50px;
  margin-bottom: 20px;
}
.right {
  width: 1006px;
  padding-top: 34px;
  padding-left: 27px;
  background: #fff;
  .spen {
    font-size: 26px;
    font-weight: 600;
    color: #616161;
  }
  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .daohang {
      font-size: 15px;
      color: #272727;
      margin-right: 25px;
      height: 30px;
      .ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        width: 400px;
        li {
          list-style: none;
          cursor: pointer;
          &.router-link-exact-active.router-link-active {
            color: #3661fe;
          }
        }
      }
    }
    .search {
      margin-right: 150px;
      display: flex;
      input {
        border: 0;
        outline: none;
        border: 1px solid #a7a7a7;
        height: 37px;
        padding-left: 10px;
      }
      .fangdajing {
        width: 45px;
        height: 40px;
        border: 1px solid #a7a7a7;
        border-left: none;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .top {
    width: 100%;
    height: 42px;
    line-height: 42px;
    background: #f5f5f5;
    display: flex;
    margin-top: 30px;
    .biaoti {
      font-size: 16px;
      color: #888;
      margin-left: 20px;
      .danhao {
        color: #666;
        font-weight: 800;
      }
      img {
        width: 23px;
        height: 23px;
        margin-left: 10px;
      }
    }
    .dianpu {
      cursor: pointer;
    }
    .mar {
      margin-left: 40px;
    }
    .kefu {
      display: flex;
      align-items: center;
    }
  }
  .bottom {
    padding-bottom: 20px;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    display: flex;
    .pic {
      width: 180px;
      height: 136px;
      margin-top: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title1 {
      height: 100%;
      border-right: 1px solid #eaeaea;
      padding-right: 10px;
      width: 345px;
      .title {
        padding-top: 34px;
        display: flex;
        justify-content: space-between;
        .miaoshu {
          color: #333;
          width: 190px;
          height: 40px;
          margin-left: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          cursor: pointer;
        }
        .shuliang {
          margin-left: 10px;
        }
      }
      .xiangsi {
        font-size: 14px;
        color: #9a9a9a;
        margin-top: 10px;
      }
      .shuxing {
        margin-left: 10px;
        font-size: 12px;
        color: #9a9a9a;
        /* margin-top: 10px; */
        display: flex;
      }
    }
    .tousu {
      width: 102px;
      border-right: 1px solid #eaeaea;
      text-align: center;
      padding-top: 34px;
      font-size: 14px;
      color: #333;
    }
    .price {
      width: 158px;
      border-right: 1px solid #eaeaea;
      padding-top: 34px;
      padding-left: 25px;
      .jiage {
        font-size: 14px;
        color: #333;
      }
      .yunfei {
        color: #a0a0a0;
        font-size: 12px;
      }
      .fangshi {
        color: #a0a0a0;
        font-size: 12px;
      }
    }
    .zhuangtai {
      width: 130px;
      padding-top: 34px;
      border-right: 1px solid #eaeaea;
      text-align: center;
      color: #333;
      .xiangqing {
        margin-top: 5px;
        cursor: pointer;
      }
      .wuliu {
        color: #dc7413;
        margin-top: 5px;
        cursor: pointer;
      }
    }
    .pay {
      width: 136px;
      padding-top: 34px;
      border-right: 1px solid #eaeaea;
      font-size: 12px;
      color: #333;
      text-align: center;
      .liji {
        color: #fff;
        background: #ff902a;
        padding: 5px 10px;
        width: 85px;
        border-radius: 5px;
        margin: 0 auto;
        margin-top: 10px;
      }
      .pingzheng {
        color: #fff;
        background: #1850ff;
        padding: 5px 10px;
        width: 85px;
        border-radius: 5px;
        margin: 0 auto;
        margin-top: 10px;
      }
      .fapiao {
        margin-top: 5px;
      }
      .liji1 {
        color: #fff;
        background: #1850ff;
        padding: 5px 10px;
        width: 85px;
        border-radius: 5px;
        margin: 0 auto;
        cursor: pointer;
        margin-top: 10px;
      }
      .daifu {
        margin-top: 5px;
      }
      .quxiao {
        margin-top: 5px;
      }
      .fapiaoshenqing {
        color: #fff;
        background: #42b983;
        padding: 5px 10px;
        width: 85px;
        border-radius: 5px;
        margin: 0 auto;
        cursor: pointer;
        margin-top: 10px;
      }
      .fapiaoshenqingzhong {
        color: #fff;
        background: #ff902a;
        padding: 5px 10px;
        width: 85px;
        border-radius: 5px;
        margin: 0 auto;
        margin-top: 10px;
        cursor: pointer;
      }
      .fapiaochakan {
        color: #fff;
        background: #1850ff;
        padding: 5px 10px;
        width: 85px;
        border-radius: 5px;
        margin: 0 auto;
        cursor: pointer;
        margin-top: 10px;
      }
    }
  }
}
</style>
